<template>
  <section class="contemporary-house">
    <section class="hero">
      <div class="hero-container">
        <div class="hero-container-title">
          <h1>Faire construire sa maison contemporaine</h1>
        </div>
        <div class="hero-container-img">
          <img
            src="@/assets/img/pages/contemporary-house-1.jpg"
            alt="La maison de plain-pied : avantages et inconvénients<"
          />
        </div>
      </div>
    </section>
    <article class="article">
      <section class="fit-content card">
        <div class="smartpage-card smartpage-card-reverse">
          <div class="smartpage-card-content">
            <div>
              <h2>Une maison au top sur les aménagements et la circulation !</h2>
              <p>Une maison contemporaine est une maison aux perspectives résolument tendues, mêlant audace des volumes et de la distribution, qualité des matériaux et un certain dépouillement dans les artifices.</p>
              <p>Ce type de modèles de maisons répond aux attentes d’une démarche sur-mesure et d’une certaine sophistication de la conception architecturale : formes complexes, plans originaux, matériaux innovants. Les grands espaces intérieurs avec de larges baies vitrées sont privilégiés alors qu’en extérieur l’épure est de rigueur, laissant s’exprimer un minimalisme intentionnel : sobriété des lignes et des couleurs, toitures terrasse ou mono-pente, ou encore combinaison avec un toit à pente traditionnelle.</p>
              <p>Toutes les inspirations sont les bienvenues !</p>
            </div>
          </div>
          <div class="smartpage-card-img">
            <img
              src="@/assets/img/pages/contemporary-house-2.jpg"
              alt="Une maison au top sur les aménagements et la circulation !"
            />
          </div>
        </div>
      </section>
      <section>
        <div class="smartpage-costing">
          <div class="smartpage-costing-content">
            <div>
              <h2>Configurez et chiffrez votre projet en moins de 2 minutes !</h2>
              <router-link class="costing-btn" :to="{ name: 'home', hash: '#hero' }">Commencer mon projet</router-link>
            </div>
            <div>
              <div>
                <img src="@/assets/img/chrono.svg" alt="chrono icon" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="fit-content card">
        <div class="smartpage-card">
          <div class="smartpage-card-content">
            <div>
              <h2>Une maison contemporaine accessible</h2>
              <p>Contemporaine, donc haut de gamme et élitiste ? Pas forcément…</p>
              <p>Le plus souvent associée au sur-mesure et à l’architecte, la maison contemporaine n’est plus uniquement l’apanage de ce type de maître d’œuvre. Certains constructeurs de maisons individuelles souhaitant étoffer leur offre s’en sont inspirés pour proposer des gammes de maisons modernes, aux lignes design, avec des plans très étudiés, des volumes généreux et disposant de matériaux de qualité.</p>
              <p>Il faut savoir que la maison moderne bénéficie de tous les attributs d’une maison contemporaine : une conception bien pensée pour s’adapter à un mode de vie combinant usages familiaux et sociaux, un mode constructif et des matériaux actuels, le respect des normes en vigueur afin d’atteindre des objectifs précis en termes de performances énergétiques et d’économies d’énergie.</p>
              <p>Alors n’hésitez pas à étudier avec votre constructeur Maisons Alysia la personnalisation de votre maison au design contemporain !</p>
            </div>
          </div>
          <div class="smartpage-card-img">
            <img
              src="@/assets/img/pages/contemporary-house-3.jpg"
              alt="Idéale pour les grands terrains, indispensable dans le cadre du handicap"
            />
          </div>
        </div>
      </section>
    </article>
  </section>
</template>

<script>
export default {
  name: 'contemporary-house',
};
</script>

<style lang="sass">
.contemporary-house
  background-color: $medium-bg
  padding-bottom: 6rem
  .hero
    @include smartpage-hero
  .article
    margin-bottom: 5rem
    .card + .card
      margin-top: 20rem
    section
      margin-top: 8rem
      &:first-child
        margin-top: 30rem
      .smartpage-card
        @include smartpage-card
      .smartpage-card-reverse
        @include smartpage-card-reverse
      .smartpage-costing
        @include smartpage-costing
      .smartpage-card-img
        margin-top: -40%
  .fit-content
    width: 90%
    max-width: 1180px
    margin: 0 auto
  @media (max-width: 768px)
    .article
      margin-bottom: 5rem
      section
        margin-top: 1.5rem
        &:first-child
          margin-top: 10rem
        .smartpage-card-img
          margin-top: 1.5rem
        .smartpage-card-reverse
          .smartpage-card-img
            margin-bottom: 0
      .card + .card
        margin-top: 1.5rem
</style>
